/* Headings: missing mobile size styles */
h1 {
  font-size: 1.875rem;
}
h2 {
  font-size: 1.5rem;
}

/* override for section text centered */
.uq-alert {text-align: left;}

/* fixes height issue with cards not in a grid */
.fund-card {
  height: auto;
  text-align: left;
}
.fund-steps {
  display: block;
  margin-top: 1.5rem;
  color: #51247a;
}
.gift-heading {
  margin-top: 0.25rem;
  margin-bottom: 1rem
}
.step-heading {
  margin-top: 1rem;
}
.donation-form {
  margin-bottom: 3rem;
}
.donation-form fieldset {
  margin-bottom: 2rem;
}
.list-confirmation {
  list-style: none;
  padding-left: 0;
}
/* add additional spacing between accordion and fund */
.fund-accordion {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.summary-note {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 2rem;
}

/* Override margin and padding issue caused by min max values on text fields */
input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  width: 100%;
}

/* Contact Number overrides */
.countryextension {
  min-width: 6rem;
}
.grid-contact-number {
  margin-top: .5rem;
  display: grid;
  grid-template-columns: 6rem auto;
  column-gap: 1rem;
}
.grid-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.grid-amounts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.grid-amounts > .uq-pill {
  margin-bottom: 0.5rem;
}

.fund-search-list {
  margin: 0;
  margin-top: -1.5rem; /* form override */
  margin-bottom: 1.5rem;
  padding: .5rem;
  border: 1px solid #e7e3e0;

  list-style: none;
  text-align: left;
}
.fund-search-list__item {
  margin: 0;
  padding: 1rem;
}
.fund-search-list__item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

/* Pill component */

.uq-pill {
  padding: 8px 16px;
  border-radius:9999px;
  cursor: pointer;
}
.uq-pill--purple {
  background-color: #51247a;
  border: 1px solid #51247a;
  color: #51247a;
}
.uq-pill--outline {
  background-color: #FFFFFF;
  border-color: #51247a;
}
.uq-pill--outline:hover, .uq-pill--outline:focus {
  background-color: #371853;
  border-color: #371853;
  color: #FFFFFF;
}

@media screen and (min-width: 37.5rem) {
  /* Headings: missing mobile size styles */
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  .fund-search {
    width: 608px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Aaron adjustments */
@media screen and (min-width: 64rem) {
  .uq-header__navigation {
    align-items: center;
  }
}

#addressSearch,
#fund-search {
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2351247A'%3e%3cg fill='none' stroke='%2351247A' stroke-width='.75'%3e%3cpath d='M6.57 11.145a4.573 4.573 0 10.003-9.146 4.573 4.573 0 00-.003 9.146zm0 0'%3e%3c/path%3e%3cpath d='M9.715 10l4.57 4.285' stroke-linecap='round' stroke-linejoin='round'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  padding-right: 2.5rem;
  background-size: 2rem;
}

.search--loading {
  background: none !important;
}

.homepage-heading {
  margin-bottom: 1rem;
}

.homepage-destination {
  font-weight: 400;
  font-size: 1.5rem;
  color: #51247a;
}

.hide {
  display: none;
}
