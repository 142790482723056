@import 'main.css';
@import 'donation.css';

.address-search-list {
  margin: -1.5rem 0 1.5rem; /* form override */
  padding: .5rem .5rem 0 .5rem;
  border: 1px solid #e7e3e0;

  list-style: none;
  text-align: left;
}
.address-search-list__item {
  margin: 0;
  padding: 1rem;
}
.address-search-list__item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.address-search-list__item:last-child {
  border-top: 1px solid #e7e3e0;
  margin: 0 -0.5rem;
  padding: 1rem 1.5rem;
}

.address-list > .fund-search-list__item:last-child,
.fund-list > .fund-search-list__item:nth-last-child(2) {
  border-top: 1px solid #e7e3e0;
}

.fund-list > .fund-search-list__item:nth-last-child(2),
.fund-list > .fund-search-list__item:last-child {
  font-weight: bold;
}

/* Fixes positioning with the dual inputs */
.phone-feedback {
  top: -1.5rem;
  margin-bottom: 0;
}

/* Position to align spinner */
.address-search,
.email-search,
.fund-search,
.phone-search {
  position: relative;
}
/* Fund searching spinner */
.address-search__spinner,
.email-search__spinner,
.payment-loading__spinner,
.phone-search__spinner {
  position: absolute;
  right: 1rem;
  top: 1.25rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

/* Fund search spinner */
.fund-search__spinner {
  position: absolute;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
}
.fund-search__spinner--error {
  top: 6.5rem;
}
.fund-search__spinner--normal {
  top: 1.25rem;
}

.address-search__spinner,
.email-search__spinner,
.phone-search__spinner {
  top: 2.25rem;
}

/* Admin fixes */
.admin-container {
  padding-bottom: 2rem;
}

.admin-table td {
  vertical-align: middle;
}

.table-input {
  margin-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0;
}

.margin-b1 {
  margin-bottom: 1rem;
}

.margin-t1 {
  margin-top: 1rem;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.payment-loading__spinner {
  top: 0.5rem;
  right: 0.5rem;
}

.uq-alert {
  margin-bottom: 2rem;
}
.uq-alert__message {
  margin-right: 2rem;
}

.uq-button:disabled {
  border: 2px solid #8EB8E2;
  background-color: #8EB8E2;
}

.uq-button--purple:disabled {
  border: 2px solid #A891BC;
  background-color: #A891BC;
}

.uq-button:disabled:hover {
  cursor: default;
  text-decoration: none;
}

.uq-button--alert {
  border: 2px solid #c01631;
  background-color: #c01631;
}
.uq-button--alert:focus,
.uq-button--alert:focus-visible,
.uq-button--alert:focus-within,
.uq-button--alert:hover {
  border: 2px solid #951126;
  background-color: #951126;
  outline-color: #951126;
}

.col-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-input {
  margin: 0 1rem !important;
  min-width: auto !important;
  max-width: none !important;
}


.admin-list-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-handle {
  display: inline-block;
  padding: 1rem;
}

.order-handle:hover {
  cursor: pointer;
}

/* Remove number input spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cc-input {
  background-position: 1rem 50%;
  background-repeat: no-repeat;
  background-size: 3rem;
  padding-left: 5rem !important;
}

.admin-modal__overlay,
.donation-overlay {
  background-color: #d7d1cc66;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.admin-modal__content,
.donation-overlay__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 0 0.5rem #d7d1cc;
  border-radius: 0.25rem;
  z-index: 20;
  min-width: 25vw;
}

.donation-overlay__content > .uq-loading-spinner {
  margin: 0 auto;
}

.donation-overlay__content > p {
  text-align: center;
}

.grid-buttons {
  margin-bottom: 2rem;
}

/* Admin Modal */
.admin-modal--hide {
  display: none;
}

.admin-modal__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d7d1cc;
  margin-bottom: 1rem;
}

.admin-modal__buttons {
  display: flex;
  justify-content: space-between;
}
